import React, { useState } from 'react';
import { createDashboard } from '../../../api/project/apiDashboardServices';
import { useDashboardContext } from '../../../contexts/project/DashboardContext';

interface AddDashboardProps {
  onClose: () => void;
  projectId: string | undefined;
}
const AddDashboard: React.FC<AddDashboardProps> = ({ onClose, projectId }) => {
  const { fetchDashboards } = useDashboardContext();
  const [title, setTitle] = useState('');

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setTitle(newTitle);

    // Title validation if needed
  };

  const handleSave = async () => {
    if (title && projectId) {
      try {
        const newDashboard = await createDashboard(projectId, title);
        if (newDashboard) {
          console.log('Dashboard Saved:', newDashboard);
          fetchDashboards(projectId);
          onClose();
        } else {
          console.error('Failed to create dashboard.');
        }
      } catch (error) {
        console.error('Error creating dashboard:', error);
      }
    }
  };
  
  return (
    <div className='fixed top-0 left-0 h-full w-full bg-gray-700 bg-opacity-50'>
      <div className='flex flex-col mx-auto p-4 mt-16 h-72 w-96 md:w-2/5 bg-white rounded-md'>
        <div className='flex flex-col p-2'>
          <h2 className='text-xl font-semibold mb-4'>Add a new Dashboard</h2>
          <label htmlFor='title' className='text-gray-600 mb-1'>
            Dashboard Title
          </label>
          <input
            id='title'
            value={title}
            onChange={handleTitleChange}
            className='p-2 border rounded'
            required
          />
        </div>
        <div className='p-2 flex flex-row justify-end mt-auto'>
        <button
  onClick={handleSave}
  disabled={!title || !projectId}
  className={`text-white font-bold mr-1 px-4 py-2 ${
    !title || !projectId
      ? 'bg-gray-300 cursor-not-allowed'
      : 'bg-gradient-to-r from-purple-600 via-purple-500 to-blue-500'
  } rounded-md`}
>
  Add Dashboard
</button>
          <button
            onClick={onClose}
            className='font-bold ml-2 px-4 py-2 border rounded-md border-gray-200 hover:bg-gray-200'
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDashboard;
