import React, { createContext, useContext, useState, ReactNode } from 'react';
import { fetchAllComponentsInProject } from '../../api/project/apiComponentServices';
import { MinimalComponent } from '../../api/apiModels';

interface ComponentProviderProps {
  children: ReactNode;
}

interface ComponentContextType {
  components: MinimalComponent[];
  fetchComponents: (projectId: string) => Promise<void>;
  isComponentLoading: boolean;
  error: Error | null;
}

const ComponentContext = createContext<ComponentContextType>({
  components: [],
  fetchComponents: async () => {},
  isComponentLoading: false,
  error: null,
});

export const useComponentContext = () => {
  return useContext(ComponentContext);
};

export const ComponentProvider: React.FC<ComponentProviderProps> = ({ children }) => {
  const [components, setComponents] = useState<MinimalComponent[]>([]);
  const [isComponentLoading, setIsComponentLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchComponents = async (projectId: string) => {
    try {
      setIsComponentLoading(true);
      const fetchedComponents = await fetchAllComponentsInProject(projectId);
      if(fetchedComponents) {
      setComponents(fetchedComponents);
      setError(null);
      }
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsComponentLoading(false);
    }
  };

  const value: ComponentContextType = {
    components,
    fetchComponents,
    isComponentLoading,
    error,
  };

  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  );
};
