import { useState } from "react";
import { FaArrowRightLong, FaRegFolderOpen } from "react-icons/fa6";
import AddProject from "./AddProject";
import MyProjects from "./MyProjects";
import { useProjectContext } from "../../contexts/project/ProjectContext";
import { Spinner } from "flowbite-react";

const Projects = () => {
  const {projects, isProjectLoading } = useProjectContext();
  const [open, setOpen] = useState(false);

  if(isProjectLoading) return (
    <div className='flex h-full flex-col items-center justify-center gap-y-2'>
       <Spinner className="text-2xl text-blue-500"/>
      <p className='text-xl font-semibold bg-gradient-to-r from-purple-600 via-purple-400 to-blue-500 bg-clip-text text-transparent'>
        Loading Projects...
      </p>
    </div>
  )

  return (
    <div className="flex flex-col h-screen py-8 px-16  dark:bg-gray-700 bg-gray-100">
      <div className="flex h-16 px-4">
        <h1 className='text-2xl font-bold dark:text-gray-50'>My Projects</h1>
      </div>
      {projects?.length > 0 ? (
        <MyProjects setOpen={() => setOpen(true)}/>
      ) : (
        <div className='flex flex-col h-screen justify-center items-center bg-white p-4'>
          <FaRegFolderOpen className="text-gray-400 text-8xl " />
          <div className='flex flex-row items-center'>
            <p
              onClick={() => setOpen(true)}
              className='cursor-pointer text-2xl font-semibold bg-gradient-to-r from-purple-600 via-purple-400 to-blue-500 bg-clip-text text-transparent'
            >
              Let’s get started with a new Project
            </p>
            <FaArrowRightLong className='text-2xl ml-2 text-blue-500' />
          </div>
          <p className='text-sm font-thin text-gray-500'>
            Projects help you keep things organized
          </p>
        </div>
      )}
      {open && <AddProject onClose={() => setOpen(false)} />}
    </div>
  );
}

export default Projects;
