import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TfiViewGrid, TfiViewListAlt } from 'react-icons/tfi';
import { TiDeleteOutline } from "react-icons/ti";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaChartBar } from "react-icons/fa";
import { useDashboardContext } from '../../../contexts/project/DashboardContext';
import { deleteDashboard } from '../../../api/project/apiDashboardServices';
import { useComponentContext } from '../../../contexts/project/ComponentContext';
import { Spinner, Table } from 'flowbite-react';
import CustomInput from '../../../blocks/CustomInput';

interface ProjectProps {
  projectId: string;
}

const MyProject: React.FC<ProjectProps> = ({ projectId }) => {
  const { dashboards, fetchDashboards, isDashboardLoading } = useDashboardContext();
  const { components, fetchComponents, isComponentLoading } = useComponentContext();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  useEffect(() => {
    if(projectId) {
      fetchDashboards(projectId);
      fetchComponents(projectId);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDashboardLoading || isComponentLoading) {
    return <div className='flex flex-1 flex-col items-center justify-center gap-y-2'>
              <Spinner className='text-3xl text-blue-500'/>
            <p className='ml-2 text-xl text-blue-500'>Loading...</p>
            </div>
  }

  const handleDeleteComponent = async (componentId: string) => {
 //   const res = await deleteComponent(project.id, componentId);
    if (componentId) {
     
      navigate('.');
    }
  }

  const handleDeleteDashboard = async (dashboardId: string) => {
    const res = await deleteDashboard(dashboardId);
    if (res) {
      fetchDashboards(projectId)
      navigate('.');
    }
  }

  const handleViewModeChange = (mode: 'grid' | 'table') => {
    setViewMode(mode);
  }

  const filteredItems = [
    ...dashboards?.map(dashboard => ({ ...dashboard, type: 'dashboard' })),
    ...components?.map(component => ({ ...component, type: 'component' }))
  ].filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if(dashboards.length === 0 && components.length === 0) {
    return (
      <div className='flex flex-1 flex-col items-center justify-center gap-y-2'>
        <p className='text-3xl text-blue-500'>No Dashboard or Component Found!</p>
        <p className='text-lg text-gray-500'>Create a new Dashboard or Component to get started.</p>
      </div>
    )
  }
  
  return (
    <div className='dark:bg-gray-700 dark:text-gray-50'>
      <div className="flex items-center justify-between mb-4">
        <h2 className='text-lg font-semibold'>All</h2>
        <CustomInput 
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <div className="flex flex-row items-center justify-center border border-gray-300 rounded-md">
          <TfiViewListAlt className={`font-extrbold text-4xl p-3 cursor-pointer ${viewMode === 'table' ? 'bg-gray-200' : ''}`} onClick={() => handleViewModeChange('table')} />
          <TfiViewGrid className={`font-extrbold text-4xl p-3 cursor-pointer ${viewMode === 'grid' ? 'bg-gray-200' : ''}`} onClick={() => handleViewModeChange('grid')} />
        </div>
      </div>
      {viewMode === 'grid' ? (
        <div className='flex flex-wrap'>
          {filteredItems.map(item => (
            <div className='flex flex-col w-60 h-40 m-2 justify-between border border-gray-300 rounded-md' key={item.id}>
              <div onClick={() => navigate(`/projects/${projectId}/${item.type}/${item.id}`)} className='w-full h-full flex justify-center items-center bg-gray-100'>
                {item.type === 'component' ? <FaChartBar className='' /> : <LuLayoutDashboard className='' />}
              </div>
              <div className='flex flex-row h-16 px-2 items-center'>
                <h3 onClick={() => navigate(`/projects/${projectId}/${item.type}/${item.id}`)} className='mr-auto text-md font-medium text-gray-800 cursor-pointer'>
                  {item.name}
                </h3>
                <TiDeleteOutline onClick={() => {
                  if (item.type === 'component') {
                    handleDeleteComponent(item.id);
                  } else if (item.type === 'dashboard') {
                    handleDeleteDashboard(item.id);
                  }
                }} className='ml-auto cursor-pointer' />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Table hoverable>
          <Table.Head>
              <Table.HeadCell className='sticky top-0 p-4' scope='col'>Type</Table.HeadCell>
              <Table.HeadCell className='sticky top-0 p-4' scope='col'>Name</Table.HeadCell>
              <Table.HeadCell className='sticky top-0 p-4' scope='col'>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {filteredItems.map(item => (
              <Table.Row key={item.id}>
                <Table.Cell onClick={() => navigate(`/projects/${projectId}/${item.type}/${item.id}`)}  className='p-4 font-medium'>
                  {item.type === 'dashboard' ? <LuLayoutDashboard /> : <FaChartBar />}
                </Table.Cell>
                <Table.Cell onClick={() => navigate(`/projects/${projectId}/${item.type}/${item.id}`)}  className="p-4 font-medium">
                  {item.name}
                </Table.Cell>
                <Table.Cell className="font-bold p-4">
                  <TiDeleteOutline onClick={() => {
                    if (item.type === 'component') {
                      handleDeleteComponent(item.id);
                    } else if (item.type === 'dashboard') {
                      handleDeleteDashboard(item.id);
                    }
                  }} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export default MyProject;
