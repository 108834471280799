import React from 'react';
import { Outlet } from 'react-router-dom';
import MySideBar from '../../components/_sidebar/MySideBar';

const MainLayout: React.FC = () => {

  return (
    <div className='flex flex-row h-screen bg-gray-50 dark:bg-gray-700 dark:text-gray-50'>
      <MySideBar />
      <div className='flex-1'>
        <Outlet  />
      </div>
    </div>
  );
};

export default MainLayout;
