import axios from 'axios';
import { DASHBOARD_ENDPOINTS } from '../apiEndpoints';
import { UpdateDashboard } from '../apiModels';

export const createDashboard = async (projectId: string, dashboardName: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put(
      DASHBOARD_ENDPOINTS.CREATE_DASHBOARD(projectId, dashboardName),
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.info(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDashboard = async (dashboard: UpdateDashboard) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.patch(
      DASHBOARD_ENDPOINTS.UPDATE_DASHBOARD,
      dashboard,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllDashboardsInProject = async (projectId: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(
      DASHBOARD_ENDPOINTS.FETCH_ALL_DASHBOARDS_IN_PROJECT(projectId),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.dashboards;
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardById = async (dashboardId: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(
      DASHBOARD_ENDPOINTS.FETCH_DASHBOARD_BY_ID(dashboardId),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDashboard = async (dashboardId: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.delete(
      DASHBOARD_ENDPOINTS.DELETE_DASHBOARD(dashboardId),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
