import React, { useState } from 'react';
import { createProject } from '../../api/project/apiProjectServices';
import { useProjectContext } from '../../contexts/project/ProjectContext';

interface AddProjectProps {
  onClose: () => void;
}

const AddProject: React.FC<AddProjectProps> = ({ onClose }) => {
  const [projectName, setProjectName] = useState('');
  const {fetchProjects} = useProjectContext();
  const handleProjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleAddProject = async () => {
    // Add logic to handle the project addition
    if (projectName.trim() === '') {
      // Handle case where project name is empty
      console.log('Project name cannot be empty.');
      return;
    }

    // Call the API function to create a new project
    const newProject = await createProject(projectName.trim());
    if(newProject) {
      fetchProjects();
    }
    // Close the AddProject modal
    onClose();
  };  

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center bg-black bg-opacity-50">
      <div className="flex flex-col h-52 w-96 bg-white p-4 rounded-md mt-16">
        <h2 className="text-xl font-semibold">Add a new Project</h2>
        <div className='flex flex-col justify-between h-full p-2'>
            <input
              type="text"
              value={projectName}
              onChange={handleProjectNameChange}
              className="bg-gray-50 my-4 p-2 border border-gray-200 rounded-md w-full"
            />
          <div className="flex">
          <button
              type="button"
              onClick={handleAddProject}
              className={`px-4 py-1.5 font-bold rounded-lg text-white ${
                projectName === '' ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-purple-500 via-blue-500 to-blue-400'
              }`}
              disabled={projectName === ''}
            >
              Add Project
            </button>
            <button
              type="button"
              onClick={onClose}
              className="ml-4 px-4 py-1.5 font-bold text-gray-700 rounded-lg border border-gray-300 hover:bg-gray-200 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
