import axios from 'axios';
import { PROJECT_ENDPOINTS } from '../apiEndpoints';

export const fetchAllProjects = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(PROJECT_ENDPOINTS.FETCH_ALL_PROJECTS, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProject = async (name: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post(PROJECT_ENDPOINTS.CREATE_PROJECT(name), null, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (projectId: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.delete(PROJECT_ENDPOINTS.DELETE_PROJECT(projectId), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
