/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MdOutlineModeEdit } from "react-icons/md";
import { LuDatabase } from "react-icons/lu";
import { IoMdSend } from 'react-icons/io';
import MessageBox from './MessageBox';
import Plot from 'react-plotly.js';
import { MessageType1, MessageType2 } from '../../../../api/_models/message';
import { useParams } from 'react-router-dom';
import { askAnalyst } from '../../../../api/project/apiConversationServices';
import { getComponentById } from '../../../../api/project/apiComponentServices';
import { ComponentDetails } from '../../../../api/apiModels';
import { Spinner } from 'flowbite-react';

const MyComponent: React.FC = () => {
  const { projectId, componentId } = useParams<{ projectId: string, componentId: string }>();
  const [currCompo, setCurrCompo] = useState<ComponentDetails>();
  const [query, setQuery] = useState<string>('');
  const [preview, setPreview] = useState<boolean>(true);
  const [messages, setMessages] = useState<(MessageType1 | MessageType2)[]>([]);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  useEffect(() => {
    const fetchComponent = async () => {
      try {
        if (projectId && componentId) {
          setIsComponentLoading(true);
          const res = await getComponentById(componentId);
          setCurrCompo(res);
          setIsComponentLoading(false);
          if (res?.conversation) {
            // Filter messages with role 'user' or 'assistant'
            const filteredMessages = res.conversation.filter((element: MessageType1) => element.role === 'user' || element.role === 'assistant');
            setMessages(prevMessages => [...prevMessages, ...filteredMessages]);
          }
          if(res.data) { 
            setMessages(prevMessages => [...prevMessages, res.data.plotly_json])
          }
        }
      } catch (error) {
        console.error('Error fetching component:', error);
      }
    };

    fetchComponent();
  }, [projectId, componentId]);

  const handleSendMessage = async () => {
    if (query.trim() !== '' && projectId && currCompo?.data_source_id && currCompo?.conversation_id) {
      setMessages(messages => [...messages, { role: 'user', content: query}]);
      setQuery('');
      try {
        setIsMessageSent(true);
        const res = await askAnalyst(currCompo?.conversation_id, projectId, currCompo?.data_source_id, {content_type: 'chat', content: query});
        if (Array.isArray(res)) {
          setMessages(messages => [...messages, res[0]]);
        } else if (res?.chart_type && res?.data) {
          setMessages(messages => [...messages, res]);
        }
        setIsMessageSent(false);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  if(isComponentLoading) {
    return (
      <div className='flex flex-col h-full w-full justify-center items-center gap-4'>
        <Spinner className='animate-pulse text-4xl text-blue-400'/>
        <p className='text-xl font-bold text-blue-500'>
          Loading Component...
        </p>
      </div>
    )
  }
  return (
    <div className='flex flex-col justify-between h-screen w-full p-4'>
      <div className='flex flex-col mb-4'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center text-2xl font-semibold bg-gradient-to-r from-purple-500 via-purple-400 to-blue-500 bg-clip-text text-transparent'>
            {currCompo?.name}
            <MdOutlineModeEdit className='ml-2 text-xl text-blue-500 cursor-pointer hover:text-blue-700' />
          </div>
          <div className='flex flex-row items-center mr-4 border rounded-md border-gray-200 dark:border-gray-600'>
            <button onClick={() => setPreview(false)} className={`font-semibold px-4 py-2 ${preview === false ? 'border rounded-l-md border-blue-600' : ''}`}>Chat</button>
            <button onClick={() => setPreview(true)} className={`font-semibold px-4 py-2 ${preview === true ? 'border rounded-r-md border-blue-600' : ''}`}>Preview</button>
          </div>
        </div>
        <p className='flex items-center text-gray-600'> <LuDatabase className='mr-2' />{currCompo?.data_source_id}</p>
      </div>

      {preview === false ?
        (<div className='flex flex-col flex-grow h-5/6 items-center justify-end'>
          {messages.length > 0 ? (
            <MessageBox messages={messages} chartClick={() => setPreview(!false)} isMessageSent={isMessageSent}/>
          ) : (
            <h1 className='text-2xl font-medium text-gray-400 m-auto'>Start asking questions to your data source</h1>
          )}
          {/* chat */}
          <div className='flex flex-row bg-gray-100 dark:bg-gray-600 items-center w-11/12 md:w-2/3 mb-4 border rounded-xl p-1'>
            <input
              className='p-1 border-none outline-none focus:ring-0 w-full dark:bg-gray-600'
              type='text'
              placeholder='What collections do I have?'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <IoMdSend onClick={handleSendMessage} className='h-10 w-12 -rotate-90 text-blue-500 cursor-pointer' />
          </div>
        </div>
        ) :
        (
          <Plot
            useResizeHandler={true}
            style={{ height: '100%', width: '100%' }}
            data={currCompo?.data?.plotly_json?.data ?? []}
            layout={{ autosize: true, ...currCompo?.data?.plotly_json?.layout ?? {} }}
            config={{ displayModeBar: false, responsive: true }}
          />
        )}
    </div>
  );
};

export default MyComponent;
