import { useState } from "react";

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
  
    const handleSubmit = (e: any) => {
      e.preventDefault();
  
      // Send the email address to the server to reset the password.
  
      // Clear the email address field.
      setEmail("");
    };
  
    return (
      <div className="h-100vh flex justify-center items-center">
      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column',  widows: '300px', height: '400px'}}>
        <label htmlFor="email">Email Address</label>
        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
  
        <button type="submit">Proceed</button>
      </form>
      </div>
    );
  };

  export default ForgetPassword;
  