import React, { useState, useEffect } from 'react';
import { Layout, Layouts, Responsive } from "react-grid-layout";
import { TiDeleteOutline } from "react-icons/ti";
import '../../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../../node_modules/react-resizable/css/styles.css';
import Plot from 'react-plotly.js';
import { ComponentDetails, DashboardDetails } from '../../../../api/apiModels';
import { getComponentById } from '../../../../api/project/apiComponentServices';
import { fetchDashboardById, updateDashboard } from '../../../../api/project/apiDashboardServices';
import { Spinner } from 'flowbite-react';

interface ResDashProps {
  dashboard: DashboardDetails;
  setDashboard: (dashboard: DashboardDetails) => void;
  saveCurrentLayout: boolean;
  setSaveCurrentLayout: () => void;
  allowLayoutChange: boolean;
  handleRemoveCompo: (dashboardId: string, componentId: string) => Promise<void>
}

const ResponsiveDashboard: React.FC<ResDashProps> = ({ dashboard, setDashboard, saveCurrentLayout, setSaveCurrentLayout, allowLayoutChange, handleRemoveCompo }) => {
  const [currentLayout, setCurrentLayout] = useState<Layout[]>(dashboard.layouts);
  const [isLayoutChanged, setIsLayoutChanged] = useState(false);
  const [componentData, setComponentData] = useState<ComponentDetails[]>();
  const [isComponentRemoving, setIsComponentRemoving] = useState(false);

  useEffect(() => {
    if (saveCurrentLayout && isLayoutChanged) {
      const saveLayout = async () => {
        try {
          const res = await updateDashboard({
            id: dashboard.id,
            layouts: currentLayout,
            name: dashboard.name,
            component_ids: dashboard.component_ids
          });
          setIsLayoutChanged(false);
          setSaveCurrentLayout();
          console.log("Layout saved:", res);
        } catch (error) {
          console.error("Error updating dashboard layout:", error);
          // Handle error appropriately
        }
      };
      saveLayout();
    }
  }, [isLayoutChanged, currentLayout, saveCurrentLayout, setSaveCurrentLayout, dashboard]);

  useEffect(() => {
    // Fetch component data for each component ID in the layout
    fetchComponentData(dashboard.component_ids);
  }, [dashboard]);

  const fetchComponentData = async (componentIds: string[]) => {
    const dataPromises = componentIds?.map(id => getComponentById(id));
    if(dataPromises) {
    const componentData = await Promise?.all(dataPromises);
    setComponentData(componentData);
    }
  };

  const onLayoutChange = (layout: Layout[], layouts: Layouts) => {
    setCurrentLayout(layout);
    setIsLayoutChanged(true);
  };


  const handleRemoveComponent = async (componentId: string) => {
    // Remove the component from the layout
    try {
      setIsComponentRemoving(prev => !prev);
    const updatedLayout = currentLayout.filter(item => item.i !== componentId);
    setCurrentLayout(updatedLayout);
    setIsLayoutChanged(true);
    const res = await handleRemoveCompo(dashboard.id, componentId);
    if(res == null) {
      const updatedDashboard = await fetchDashboardById(dashboard.id);
      if(updatedDashboard)
        setDashboard(updatedDashboard);
    }
    setIsComponentRemoving(prev => !prev);
    } catch {

    }
  };

  return (
    <div className='flex-1 dark:bg-gray-600'>
      {isComponentRemoving ? 
        <div className='flex-1 bg-gray-50 dark:bg-gray-700 p-8 rounded-lg'>
          <Spinner className='flex-1' />
        </div> : 
        <Responsive
          layouts={{ lg: currentLayout }} 
          width={1200}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          onLayoutChange={allowLayoutChange ? onLayoutChange : undefined} 
          isDraggable={allowLayoutChange}
          isResizable={allowLayoutChange}
          useCSSTransforms={true}
          className='border border-gray-400'
        >
          {currentLayout.map((layoutItem, index) => {
            const componentId = layoutItem.i;
            const data = componentData?.find(data => data?.id === componentId);
            return (
              <div key={componentId} className='relative bg-gray-100' data-grid={layoutItem}>
                {data ? ( 
                  <Plot
                    useResizeHandler={true}
                    style={{ width: '100%', height: '100%' }}
                    data={data?.data.plotly_json.data}
                    layout={{ autosize: true, ...data?.data.plotly_json.layout }}
                    config={{ displayModeBar: false, responsive: true, staticPlot: true }}
                  />
                  ) : (
                  <div>Loading...</div> // Display a loading indicator if data is not available
                )}
                {allowLayoutChange && (
                  <button onClick={() => handleRemoveComponent(componentId)} title='remove' className='absolute top-0 right-0 opacity-50 hover:opacity-100 p-1'>
                    <TiDeleteOutline className='dark:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-gray-500'/>
                  </button>
                )}
             </div>
            );
          })}
        </Responsive>
        }
    </div>
  );
}

export default ResponsiveDashboard;
