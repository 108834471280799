import React from 'react';
import { FaGoogle } from 'react-icons/fa';
import '../styles/custom.css';

const GoogleLogin: React.FC = () => {
  const handleLoginWithGoogle = async () => {
    try {
      // console.info('login with google clicked')
      // const loginResponse = await axios.get(`${BASE_URL}${GOOGLE_ENDPOINTS.LOGIN_GOOGLE}`, {
      //   headers: {'Content-Type': 'application/json'}
      // });
      // console.info('login redirect res: ', loginResponse)
     // window.location.href = loginResponse?.url;
    } catch (error) {
      console.error('Error initiating Google login:', error);
      // Handle error as needed
    }
  };

  return (
    <button className="flex flex-row items-center px-3 py-1 rounded-md border-2 border-gray-300 shadow" onClick={handleLoginWithGoogle}>
      <FaGoogle className="mr-2" />
      <h1>Log in with Google</h1>
    </button>
  );
};

export default GoogleLogin;
