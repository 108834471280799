import React, { useEffect, useState } from 'react';
import { MdOutlineModeEdit } from 'react-icons/md';
import { TbComponents } from 'react-icons/tb';
import { GrDocumentConfig } from 'react-icons/gr';
import { CgEditUnmask } from 'react-icons/cg';
import { MdOutlineDesignServices } from "react-icons/md";
import { useParams } from 'react-router-dom';
import ResponsiveDashboard from './ResponsiveDashboard';
import AddCompToDash from './AddCompToDash';
import { Spinner } from 'flowbite-react';
import { DashboardDetails, UpdateDashboard } from '../../../../api/apiModels';
import { fetchDashboardById, updateDashboard } from '../../../../api/project/apiDashboardServices';

const MyDashboard: React.FC = () => {
  const {projectId, dashboardId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [designMode, setDesignMode] = useState(false);
  const [dashboard, setDashboard] = useState<DashboardDetails>();
  const [addCompo, setAddCompo] = useState(false);
  const [saveLayout, setSaveLayout] = useState(false);

useEffect(() => {
  const fetchData = async () => {
    // Fetch the dashboard name based on the ID
    if (dashboardId && projectId) {
      setIsLoading(true);
      try {
        const res = await fetchDashboardById(dashboardId);
        if (res) {
          setDashboard(res);
        }
      } catch (error) {
        
      }
      setIsLoading(false);
    }
  };

  fetchData();

}, [dashboardId, projectId]); 


  const handleExit = () => {
    setDesignMode(false);
  }

  const handleRemoveCompo = async (dashboardId: string, componentId: string) => {
    if(dashboard) {
    try {
      // Remove component ID from component_ids array
      const updatedComponentIds = dashboard?.component_ids.filter(id => id !== componentId);
  
      // Remove layout item with the corresponding component ID
      const updatedLayout = dashboard?.layouts.filter(item => item.i !== componentId);
  
      // Update the dashboard object
      const updatedDashboard: UpdateDashboard = {
        id: dashboard.id,
        name: dashboard.name,
        component_ids: updatedComponentIds || [],
        layouts: updatedLayout || [], // If updatedLayout is null or undefined, set it to an empty array
      };
  
      const res = await updateDashboard(updatedDashboard);
      // console.info('updated ', res);
      if(res == null) {
         const res2 = await fetchDashboardById(dashboardId)
         if(res2) {
          setDashboard(res2);
         }
      }

    } catch (error) {
      console.error('Error removing component from dashboard:', error);
    }
    }
  };  
  
     if(isLoading) {
      return <div className='flex h-full justify-center items-center'>
        <Spinner className='text-3xl text-blue-500'/>
      </div>
     }
  return (
    <div className="flex flex-col p-4 bg-gray-50 dark:bg-gray-700 dark:text-gray-50">
      <div className="flex justify-between items-center mb-4">
            <h1 className="flex items-center text-2xl font-semibold bg-gradient-to-r from-purple-500 via-blue-600 to-blue-500 bg-clip-text text-transparent">
              {dashboard?.name}
              <MdOutlineModeEdit className="ml-2 text-xl text-blue-500 cursor-pointer hover:text-blue-700" />
            </h1>
        <div className='flex mr-4'>
          {designMode === true ? (
            <>
              <button onClick={() => handleExit()} className="font-semibold p-2 ml-auto border border-blue-500 rounded-md bg-gradient-to-r from-purple-500 via-blue-600 to-blue-500 bg-clip-text text-transparent">
                Exit Design Mode
              </button>
              <button onClick={() => setSaveLayout(true)} className="font-semibold text-white p-2 ml-4 rounded-md bg-gradient-to-r from-purple-500 via-blue-600 to-blue-500">
                Save Changes
              </button>
            </>
          ) : (
            <button onClick={() => setDesignMode(true)} className='flex items-center font-semibold px-4 py-2 rounded-md bg-gradient-to-r from-purple-500 via-blue-600 to-blue-500 text-gray-50'>
              <MdOutlineDesignServices className='mr-2'/>
              Design
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row">
        {designMode === true && 
          <div className="flex flex-col border rounded-md border-gray-300">
            <div onClick={() => setAddCompo(true)} className="flex items-center px-4 py-2 border-b-2 border-gray-300 cursor-pointer">
              <TbComponents className="mr-2" /> Components
            </div>
            <div className="flex items-center px-4 py-2 border-b-2 border-gray-300">
              <GrDocumentConfig className="mr-2" /> Config Editor
            </div>
            <div className="flex items-center px-4 py-2 border-gray-300">
              <CgEditUnmask className="mr-2" /> Advanced
            </div>
          </div>
        }
        {/* Use Responsive directly */}
         {dashboard && dashboard.component_ids.length > 0 ? 
          <div className='flex-1'>
            <ResponsiveDashboard dashboard={dashboard} setDashboard={setDashboard} saveCurrentLayout = {saveLayout} setSaveCurrentLayout = {() => setSaveLayout(false)} allowLayoutChange={designMode} handleRemoveCompo={handleRemoveCompo}/>
          </div> :
          <div className='flex w-full h-2/5 text-lg justify-center items-center'>
            No Component Added!
          </div>
        }
         {addCompo && dashboardId && projectId && dashboard && <AddCompToDash projectId={projectId} onClose={() => setAddCompo(false)} dashboard={dashboard} setDashboard={setDashboard}/>}
      </div>
       
    </div>
  );
};

export default MyDashboard;
