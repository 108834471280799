import React, { useState, useEffect, useRef } from 'react';
import { IoMdSend } from "react-icons/io";
import { useParams } from 'react-router-dom';
import { askAnalyst } from '../../api/project/apiConversationServices';
import AssistantMessage from '../../components/_chat/AssistantMessage';
import UserMessage from '../../components/_chat/UserMessage';
import Plot from 'react-plotly.js';
import { MessageType1, MessageType2, isChartMessage } from '../../api/_models/message';
import { Spinner } from 'flowbite-react';

const Crunch: React.FC = () => {
  const { projectId, dataSourceId } = useParams();
  const [conversationId, setConversationId] = useState<string>(new Date().toISOString());
  const [newMessage, setNewMessage] = useState<string>('');
  const [messages, setMessages] = useState<(MessageType1 | MessageType2)[]>([]);
  const [isSpecialMessage, setIsSpecialMessage] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (!conversationId) {
      setConversationId(new Date().toISOString());
    }
  }, [conversationId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (!projectId || !dataSourceId || !newMessage) return;

    try {
      const message = { role: 'user', content: newMessage };
      setMessages(prevMessages => [...prevMessages, message]);
      setNewMessage('');
      const response = await askAnalyst(conversationId, projectId, dataSourceId, { content_type: 'chat', content: newMessage });
      
      if (Array.isArray(response)) {
        setMessages(prevMessages => [...prevMessages, response[0]]);
      } else if (response.chart_type && response.data) {
        setMessages(prevMessages => [...prevMessages, response]);
      }
    } catch (error) {
      console.error('Failed to ask analyst:', error as Error);
    }
  };  

  const handleSpecialMessage = async (text: string) => {
    if (!projectId || !dataSourceId || !conversationId || !text) return;

    try {
      setIsSpecialMessage(true);
      const response = await askAnalyst(conversationId, projectId, dataSourceId, { content_type: text, content: text });
      
      if (response) {
        const message = text === '/run_query' ? response : { role: 'assistant', content: response };
        if(text === '/save_component') {
          setIsSaved(true);
        }
        setMessages(prevMessages => [...prevMessages, message]);
      }

      setIsSpecialMessage(false);
    } catch (error) {
      console.error('Failed to send special message:', error as Error);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const isInputNotEmpty = newMessage.trim() !== '';

  return (
    <div className="flex flex-col justify-center items-center h-screen p-4 bg-white">
      <div className='flex flex-col h-5/6 justify-evenly items-center w-full'>
        {messages.length === 0 ? (
          <div className="text-4xl font-medium">Let’s crunch some data!</div>
        ) : (
          <div className='flex flex-col h-full w-2/3 overflow-scroll scroll-smooth border border-gray-300 rounded-md p-2' ref={messagesEndRef} style={{scrollbarWidth: 'thin'}}>
            {isSpecialMessage ? (
              <div className='flex flex-col justify-center items-center h-full'>
                <Spinner className='text-4xl text-blue-500'/>
              </div>
            ) : (
              <div className='flex-1'>
                {messages.map((message, index) => (
                  <div key={index} className="flex w-full">
                    {isChartMessage(message) ? 
                      <div className='ring-2 ring-gray-300 m-2 rounded-md relative'>
                        <Plot
                          data={(message as MessageType2).data.data}
                          layout={(message as MessageType2).data.layout}
                          config={{displayModeBar: false, responsive: true, staticPlot: true}}

                          style={{ width: "100%", height: "100%", position: 'relative' }}
                        />
                        { !isSaved && <button onClick={() => handleSpecialMessage('/save_component')} className='absolute bottom-0 left-0 m-2 p-2 border-2 border-blue-500 rounded-md'>
                          Save
                          </button>
                        } 
                      </div>
                      :
                      <div className='flex-1'>
                        {message.role === 'user' ? 
                          <UserMessage text={message.content} /> : 
                          <AssistantMessage text={message.content} />
                        }
                      </div>
                    }
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className='flex flex-row justify-center items-center w-2/3'>
        <div title='ask question' className={`flex flex-row items-center w-full m-2 border-2 ${isInputNotEmpty ? 'border-blue-500 ring-1' : 'border-gray-400'} rounded-md`}>
          <input
            type="text"
            placeholder="Type your question..."
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            className="mx-1 my-auto p-2 w-full outline-none border-none focus:outline-none focus:ring-0"
            onKeyPress={e => e.key === 'Enter' && isInputNotEmpty && handleSend()}
            autoComplete="off"
          />
          <IoMdSend className='h-10 w-10 -rotate-90 text-blue-500 cursor-pointer' title='send' onClick={handleSend} />
        </div>
        <button onClick={() => handleSpecialMessage('/run_query')} title='Run Query' className='flex items-center justify-center m-1 p-1 border-2 border-gray-400 hover:border-blue-500 rounded-md'>
          <p className='text-xs font-semibold text-gray-600'>Run Query</p>
        </button>
      </div>
    </div>
  );
};

export default Crunch;
