import { useRoutes } from "react-router-dom";
import MainLayout from "./pages/_layout/MainLayout";
import SignUpPage from "./pages/_signup/SignUpPage";
import ForgetPassword from "./pages/ForgetPassword";
import PricingPage from "./pages/PricingPage";
import HeroBanner from "./pages/_landing/HeroBanner";
import LandingPage from "./pages/_landing/LandingPage";
import LoginPage from "./pages/_login/LoginPage";
import Projects from "./pages/_projects/Projects";
import Project from "./pages/_projects/_project/Project";
import MyDashboard from "./pages/_projects/_project/_dashboard/MyDashboard";
import MyComponent from "./pages/_projects/_project/_component/MyComponent";
import { ProjectProvider } from "./contexts/project/ProjectContext";
import { DashboardProvider } from "./contexts/project/DashboardContext";
import { ComponentProvider } from "./contexts/project/ComponentContext";
import { DataSourceProvider } from "./contexts/DataSourceContext";
import MyDataSourcesPage from "./pages/_data_sources/MyDataSourcesPage";
import Crunch from "./pages/_crunch/Crunch";
import { useAuthContext } from "./contexts/AuthContext";

function App() {
  const accessToken = localStorage.getItem('accessToken');
  const {isAuthenticated } = useAuthContext();
  const routes = useRoutes([
    {
      path: "/",
      element:
        accessToken && isAuthenticated ? (
          <ProjectProvider>
            <DataSourceProvider>
              <ComponentProvider >
                <DashboardProvider >
                  <MainLayout />
                </DashboardProvider>
              </ComponentProvider>
            </DataSourceProvider>
          </ProjectProvider>
        ) : (
          <LandingPage />
        ),
      children:
        accessToken && isAuthenticated
          ? [
              { index: true, element: <Projects />},
              { path: "projects/:projectId", element: <Project />},
              { path: "projects/:projectId/component/:componentId", element: <MyComponent /> },
              { path: "projects/:projectId/dashboard/:dashboardId", element: <MyDashboard /> },
              { path: "datasources", element: <MyDataSourcesPage /> },
              { path: "projects/:projectId/chat/:dataSourceId", element: <Crunch /> }
            ]
          : [
              // public child routes
              { index: true, element: <HeroBanner /> },
              { path: "product", element: <LoginPage /> },
              { path: "pricing", element: <PricingPage /> },
            ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <SignUpPage />,
    },
    {
      path: "/forget-password",
      element: <ForgetPassword />,
    },
  ]);

  return routes;
}

export default App;
