import React, { useState } from 'react';
import { TfiViewListAlt } from 'react-icons/tfi';
import { TfiViewGrid } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../contexts/project/ProjectContext';
import CustomInput from '../../blocks/CustomInput';
import { Table } from 'flowbite-react';
import dayjs from 'dayjs';

interface MyProjectsProps {
  setOpen: () => void; // Add this prop
}

const MyProjects: React.FC<MyProjectsProps> = ({ setOpen }) => {
  const { projects } = useProjectContext();
  const [search, setSearch] = useState<string>('');
  const [viewMode, setViewMode] = useState<'table' | 'card'>('table');

  const navigate = useNavigate();
  const filteredProjects = projects.filter(project =>
    project.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className='flex flex-col px-4 py-2' style={{ minWidth: 'min-content' }}>
      <div className='flex flex-col md:flex-row md:justify-between md:items-center mb-4'>
        <CustomInput 
         placeholder='Search Projects'
         value={search}
         onChange={(e) => setSearch(e.target.value)}
        />
        <div className='flex flex-row h-12 px-2 items-center'>
          <div className='flex flex-row items-center justify-center border border-gray-300 rounded-md'>
            <TfiViewListAlt
              onClick={() => setViewMode('table')}
              className={`font-extrabold text-4xl p-3 cursor-pointer ${
                viewMode === 'table' ? ' bg-gray-200' : ''
              }`}
            />
            <TfiViewGrid
              onClick={() => setViewMode('card')}
              className={`font-extrabold text-4xl p-3 cursor-pointer ${
                viewMode === 'card' ? ' bg-gray-200' : ''
              }`}
            />
          </div>
          <button
            onClick={setOpen}
            className='text-white font-bold rounded-md ml-8 px-4 py-2 bg-gradient-to-r from-purple-600 via-purple-500 to-blue-500'
          >
            Add Project
          </button>
        </div>
      </div>
      {
        viewMode === "table" ?
        <div className='h-[70vh] max-h-[70vh] overflow-auto'>  
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell  className='sticky top-0' scope='col'>Project Name</Table.HeadCell>
              <Table.HeadCell  className='sticky top-0' scope='col'>Last Activity</Table.HeadCell>
              <Table.HeadCell  className='sticky top-0' scope='col'>Created At</Table.HeadCell>
              <Table.HeadCell  className='sticky top-0' scope='col'>
                <span className="sr-only">View</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {filteredProjects.map(project => (
                <Table.Row
                  key={project.id}
                  // className='border-b-2 border-gray-100 cursor-pointer'
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  <Table.Cell className='p-3 font-medium'>{project.name}</Table.Cell>
                  <Table.Cell className='p-3 text-gray-500'>{dayjs(project.created_at).format("Do MMM YYYY, hh:mm a")}</Table.Cell>
                  <Table.Cell className='p-3 font-bold'>{project.created_by}</Table.Cell>
                  <Table.Cell className='p-3 font-bold'>View</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
          :
        viewMode === "card" ?
        <div className='flex flex-wrap overflow-y-scroll overflow-x-hidden w-full mt-4 gap-4 md:gap-8 lg:gap-10'>
          {filteredProjects.map(project => (
            <div
              key={project.id}
              className='flex flex-col justify-between border border-gray-300 rounded-md h-80 w-96 cursor-pointer'
              onClick={() => navigate(`/projects/${project.id}`)}
            >
              <div className='flex justify-center items-center h-3/5 bg-gray-200'></div>
              <div className='flex flex-col'>
                <h2 className='text-2xl font-bold px-2 pb-1'>{project.name}</h2>
                <p className='px-2'>Last Activity: {project.created_at}</p>
              </div>
              <p className='p-2'>Created By: {project.created_by}</p>
            </div>
          ))}
        </div>
          :
        null
      }
    </div>
  );
};

export default MyProjects;
