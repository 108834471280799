import axios from 'axios';
import { AUTH_ENDPOINTS } from './apiEndpoints';

// Function to handle user signup
export const signup = async (email: string) => {
  try {
    const response = await axios.post(
      AUTH_ENDPOINTS.SIGNUP,
      { email },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to request OTP
export const getOtp = async (email: string) => {
  try {
    const response = await axios.post(
      AUTH_ENDPOINTS.GET_OTP,
      { email },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to verify OTP
export const verifyOtp = async (verifyOtpToken: string, otp: string) => {
  try {
    const response = await axios.post(
      AUTH_ENDPOINTS.VERIFY_OTP,
      { verifyOtpToken, otp },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to create password
export const createPwd = async (verifiedEmailOtpToken: string, email: string, password: string) => {
  try {
    const response = await axios.post(
      AUTH_ENDPOINTS.CREATE_PASSWORD,
      { verifiedEmailOtpToken, email, password },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle user login
export const login = async (username: string, password: string) => {
  try {
    const response = await axios.post(
      AUTH_ENDPOINTS.LOGIN,
      { username, password },
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
