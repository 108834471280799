import React, { useEffect, useState } from 'react';
import { useComponentContext } from '../../../../contexts/project/ComponentContext';
import { DashboardDetails, UpdateDashboard } from '../../../../api/apiModels';
import { fetchDashboardById, updateDashboard } from '../../../../api/project/apiDashboardServices';
import { Spinner } from 'flowbite-react';

interface AddCompToDashProps {
    projectId: string;
    onClose: () => void;
    dashboard: DashboardDetails;
    setDashboard: React.Dispatch<React.SetStateAction<DashboardDetails | undefined>>;
}

const AddCompToDash: React.FC<AddCompToDashProps> = ({ projectId, onClose, dashboard, setDashboard }) => {
    const { fetchComponents, components } = useComponentContext();
    const [selectedComponent, setSelectedComponent] = useState<string | null>(null);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        try {
            fetchComponents(projectId);
        } catch (error) {
            console.info(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckboxChange = (componentId: string) => {
        setSelectedComponent(prevSelectedComponent =>
            prevSelectedComponent === componentId ? null : componentId
        );
    };

    const handleAddToDashboard = async () => {
        try {
            if (!selectedComponent) {
                console.error('No component selected.');
                return;
            }

            // Calculate the y-coordinate for the new component
            const maxY = dashboard.layouts.reduce((max, item) => Math.max(max, item.y), 0); // Assume maxY is 0 initially
            const updatedLayouts = [
                ...dashboard.layouts,
                {
                    x: 0, // Always starting from x: 0
                    y: maxY + 1, // Placing the new component below existing ones
                    w: 4,
                    h: 4,
                    i: selectedComponent
                }
            ];

            const updatedDashboard: UpdateDashboard = {
                id: dashboard.id,
                name: dashboard.name,
                component_ids: [...dashboard.component_ids, selectedComponent],
                layouts: updatedLayouts,
            };

            setIsAdding(true);
            const res = await updateDashboard(updatedDashboard);
            setIsAdding(false);

            if (res == null) {
                const updatedDashboardData = await fetchDashboardById(dashboard.id);
                if (updatedDashboardData) {
                    setDashboard(updatedDashboardData);
                }
            }
            onClose();
        } catch (error) {
            console.error('Error adding component to dashboard:', error);
        }
    };

    const componentsNotInDashboard = components.filter(
        component => !dashboard.component_ids.includes(component.id)
    );

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
            <div className="bg-gray-50 dark:bg-gray-700 p-8 rounded-lg shadow-lg">
                {isAdding ? <Spinner className='flex-1' /> :
                    <>
                        <h2 className="text-2xl font-semibold mb-2">Add Component</h2>
                        <div className='h-96 mb-2 space-y-2 overflow-y-scroll'>
                            {componentsNotInDashboard.map(component => (
                                <div key={component.id} className="flex items-center px-2 py-1 border rounded border-gray-300 bg-gray-50 dark:bg-gray-500">
                                    <input
                                        type="checkbox"
                                        className='border border-gray-500 rounded'
                                        checked={selectedComponent === component.id}
                                        onChange={() => handleCheckboxChange(component.id)}
                                    />
                                    <label className="ml-2 text-lg">{component.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end pt-2">
                            <button onClick={onClose} className="px-4 py-2 mr-2 bg-gray-300 rounded-lg">Close</button>
                            <button onClick={handleAddToDashboard} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Add to Dashboard</button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default AddCompToDash;