import React from 'react';

interface CustomButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, onClick }) => {
  return (
    <button
      className="flex items-center font-semibold px-4 py-2 rounded-md bg-gradient-to-r from-purple-500 via-blue-600 to-blue-500 text-gray-50"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CustomButton;
