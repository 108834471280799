import { FaCheck } from "react-icons/fa";

const PricingPage = () => {
    return (
          <div className="w-full h-screen mx-auto px-4 sm:px-6 lg:px-8 pt-20">
            <h1 className="text-center text-3xl font-bold">Our Pricing Plans</h1>
            <p className="text-center text-gray-500 mt-4">
              Choose the plan that's right for you.
            </p>
    
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              <div className="bg-white rounded-lg shadow-lg p-8">
                <h2 className="text-xl font-bold text-center">Basic</h2>
                <p className="text-center text-gray-500 mt-2">
                  For small businesses and startups
                </p>
                <ul className="list-none mt-6">
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>100 API requests per month</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Basic support</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Access to our knowledge base</span>
                  </li>
                </ul>
                <button className="btn btn-primary mt-8 w-full">
                  Choose Plan
                </button>
              </div>
    
              <div className="bg-white rounded-lg shadow-lg p-8">
                <h2 className="text-xl font-bold text-center">Standard</h2>
                <p className="text-center text-gray-500 mt-2">
                  For growing businesses
                </p>
                <ul className="list-none mt-6">
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>1000 API requests per month</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Standard support</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Access to our knowledge base</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Free onboarding session</span>
                  </li>
                </ul>
                <button className="btn btn-primary mt-8 w-full">
                  Choose Plan
                </button>
              </div>
    
              <div className="bg-white rounded-lg shadow-lg p-8">
                <h2 className="text-xl font-bold text-center">Enterprise</h2>
                <p className="text-center text-gray-500 mt-2">
                  For large businesses and organizations
                </p>
                <ul className="list-none mt-6">
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Unlimited API requests</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Priority support</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Access to our knowledge base</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Free onboarding session</span>
                  </li>
                  <li className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>Customizable pricing plans</span>
                  </li>
                </ul>
                <button className="btn btn-primary mt-8 w-full">
                  Choose Plan
                </button>
              </div>
            </div>
          </div>
      );
    };
    
 export default PricingPage;