import React, { createContext, useContext, useState, ReactNode } from 'react';
import { fetchAllDashboardsInProject } from '../../api/project/apiDashboardServices';
import { Dashboard } from '../../api/apiModels';

interface DashboardProviderProps {
  children: ReactNode;
}

// Define the shape of the dashboard context
interface DashboardContextType {
  dashboards: Dashboard[];
  fetchDashboards: (projectId: string) => Promise<void>;
  isDashboardLoading: boolean; // Include isDashboardLoading in the context type
  error: Error | null;
}

// Initialize the context with default values
const DashboardContext = createContext<DashboardContextType>({
  dashboards: [],
  fetchDashboards: async () => {},
  isDashboardLoading: false, // Default value for isDashboardLoading
  error: null,
});

// Custom hook to access the dashboard context
export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

// DashboardProvider component to wrap your application and provide dashboard context
export const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [isDashboardLoading, setIsDashboardLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  // Function to fetch dashboards
  const fetchDashboards = async (projectId: string) => {
    try {
      setIsDashboardLoading(true); // Set loading state to true when fetching
      const fetchedDashboards = await fetchAllDashboardsInProject(projectId);
      setDashboards(fetchedDashboards);
      setError(null); // Clear any previous errors if fetch is successful
    } catch (error) {
      setError(error as Error); // Set error state if fetch fails
    } finally {
      setIsDashboardLoading(false); // Set loading state to false after fetching, regardless of success or failure
    }
  };

  const value: DashboardContextType = {
    dashboards,
    fetchDashboards,
    isDashboardLoading,
    error,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
