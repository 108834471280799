import axios from 'axios';
import { COMPONENT_ENDPOINTS } from '../apiEndpoints';

const getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
});

export const fetchAllComponentsInProject = async (projectId: string) => {
  try {
    const response = await axios.get(COMPONENT_ENDPOINTS.FETCH_ALL_COMPONENTS_IN_PROJECT(projectId), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getComponentById = async (componentId: string) => {
  try {
    const response = await axios.get(COMPONENT_ENDPOINTS.READ_ITEM(componentId), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteComponentById = async (componentId: string) => {
  try {
    const response = await axios.delete(COMPONENT_ENDPOINTS.DELETE_ITEM(componentId), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
