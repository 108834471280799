// UserMessage.tsx
import React from 'react';
import { HiUserCircle } from 'react-icons/hi';

interface UserMessageProps {
  text: string;
}

const UserMessage: React.FC<UserMessageProps> = ({ text }) => (
  <div className='flex justify-end align-top mb-2 mr-2 ml-auto'>
    <div className='bg-blue-500 text-white rounded-md max-w-fit gap-1 py-1 px-2 ml-1'>
      {text}
    </div>
      <HiUserCircle className='flex justify-center ml-1 text-4xl'/>
  </div>
);

export default UserMessage;
