import React from 'react';
import { SiMongodb } from "react-icons/si";
import { useNavigate } from 'react-router-dom';
import { DataSource } from '../../api/apiModels';

interface DataSourceCardProps {
  dataSource: DataSource ;
}

const DataSourceCard: React.FC<DataSourceCardProps> = ({ dataSource }) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col justify-around items-center w-48 h-36 bg-white p-2'  onClick={()=>navigate('/chatwithdatasource', {state: { data_source_id: dataSource.id }})}>
       <SiMongodb className=' text-green-600 w-20 h-20'/>
      <p className='text-lg font-medium px-2 h-10'>{dataSource.name}</p>
    </div>
  );
};

export default DataSourceCard;
