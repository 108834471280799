import React from "react";

const CustomFooter: React.FC = () => {
  return (
    <footer className="flex flex-col justify-center bg-gradient-to-t from-blue-200 via-blue-50 to-blue-100 p-8">
      <div className="mx-auto md:flex-row justify-between">
        <div className="flex flex-col justify-center text-center mb-8 md:mb-0 text-blue-900">
          <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
          <p className="text-lg">Email: arllamv1@gmail.com</p>
        </div>
        {/*
        <div className="md:w-1/3 mb-8 md:mb-0">
          <h2 className="text-2xl font-bold mb-4">Quick Links</h2>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/features">Features</a>
            </li>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>

        <div className="md:w-1/3">
          <h2 className="text-2xl font-bold mb-4">Connect With Us</h2>
          <div className="flex space-x-4">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
         */}
      </div>
      <div className="mt-8">
        <p className="text-center text-blue-900">
          &copy; 2024 ARLLaM. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default CustomFooter;
