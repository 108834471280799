import React, { useState } from 'react';
import CustomButton from './CustomButton';
import { signup, getOtp, verifyOtp, createPwd } from '../api/apiAuthServices';
import { useNavigate } from 'react-router-dom';

enum SignUpStep {
  SignUp,
  VerifyOtp,
  CreatePassword,
}

const CustomSignUpForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signUpStep, setSignUpStep] = useState(SignUpStep.SignUp);
  const [verifyOtpToken, setVerifyOtpToken] = useState('');
  const [verifiedEmailOtpToken, setVerifiedEmailOtpToken] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      const signupResponse = await signup(email);
      if (signupResponse) {
        const getOtpResponse = await getOtp(email);
        if (getOtpResponse?.verify_otp_token) {
          setVerifyOtpToken(getOtpResponse.verify_otp_token);
          setSignUpStep(SignUpStep.VerifyOtp);
        } else {
          console.error('Error getting OTP');
          // Handle OTP retrieval failure
        }
      } else {
        console.error('Error signing up:', signupResponse.error);
        // Handle signup failure
      }
    } catch (error) {
      console.error('Sign up failed:', error);
      // Handle error gracefully, e.g., display a user-friendly message
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const verifyOtpResponse = await verifyOtp(verifyOtpToken, otp);
      if (verifyOtpResponse?.verified_email_otp_token) {
        setVerifiedEmailOtpToken(verifyOtpResponse.verified_email_otp_token);
        setSignUpStep(SignUpStep.CreatePassword);
      } else {
        console.error('OTP verification failed');
        // Handle OTP verification failure
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  const handleCreatePassword = async () => {
    try {
      await createPwd(verifiedEmailOtpToken, email, password);
      console.log('Sign up successful!');
      navigate('/login');
    } catch (error) {
      console.error('Error creating password:', error);
      // Handle error and provide feedback to the user
    }
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      {signUpStep === SignUpStep.SignUp && (
        <>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <CustomButton onClick={handleSignUp}>
            Sign Up
          </CustomButton>
        </>
      )}

      {signUpStep === SignUpStep.VerifyOtp && (
        <>
          <div className="mb-4">
            <label className="block text-gray-700">Enter OTP:</label>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <CustomButton onClick={handleVerifyOtp}>Verify OTP</CustomButton>
        </>
      )}

      {signUpStep === SignUpStep.CreatePassword && (
        <>
          <div className="mb-4">
            <label className="block text-gray-700">Create Password:</label>
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Confirm Password:</label>
            <input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <CustomButton onClick={handleCreatePassword}>Create Password</CustomButton>
        </>
      )}
    </div>
  );
};

export default CustomSignUpForm;
