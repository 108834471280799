import React, { useState } from 'react';
import AddDataSourceForm from '../../components/_data_sources/AddDataSourceForm';
import { useDataSourceContext } from '../../contexts/DataSourceContext';
import MyDataSources from './MyDataSources';

const MyDataSourcesPage: React.FC = () => {
  const { dataSources } = useDataSourceContext();
  const [addDataSource, setAddDataSouorce] = useState(false);
  return (
    <div className="flex flex-col">
      <div className="flex h-16 items-center px-4">
        <h1 className='text-2xl font-bold'>My Data Sources</h1>
      </div>
      <MyDataSources dataSources={dataSources} addNewDataSource={() => setAddDataSouorce(true)}/>
      {dataSources && dataSources?.length === 0 && (
        <div className="data-component-dashboard-empty-state">
          <h5>No Data Source Found!</h5>
        </div>
      )}

      {addDataSource && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <AddDataSourceForm onClose={() => setAddDataSouorce(false)} />
        </div>
      )}
    </div>
  );
};

export default MyDataSourcesPage;
