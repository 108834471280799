import React, { useState } from 'react';
import { useDataSourceContext } from '../../../contexts/DataSourceContext';
import { useNavigate } from 'react-router-dom';

interface AddComponentProps {
  onClose: () => void;
  projectId: string | undefined;
}

const AddComponent: React.FC<AddComponentProps> = ({ onClose, projectId }) => {
  const { dataSources } = useDataSourceContext();
  const [dataSourceId, setDataSourceId] = useState('');
  const navigate = useNavigate();

  const handleSelect = () => {
    // You can handle the selection logic here
    navigate(`/projects/${projectId}/chat/${dataSourceId}`)
  };

  return (
    <div className='fixed top-0 left-0 w-full h-full flex justify-center bg-black bg-opacity-50'>
      <div className='flex flex-col h-72 w-96 md:w-2/5 p-4 mt-16 bg-white rounded-md'>
        <h2 className='text-xl font-semibold mb-4'>Add a new Component</h2>
        <label htmlFor='dataSource' className='my-1'>
          Select a Datasource
        </label>
        <select
          id='dataSourceId'
          value={dataSourceId}
          onChange={(e) => setDataSourceId(e.target.value)}
          className='p-2 border rounded mb-4 w-full'
          required
        >
          <option value='' disabled>
            Select DataSource
          </option>
          {dataSources?.map((source) => (
            <option key={source.id} value={source.id}>
              {source.name}
            </option>
          ))}
        </select>

        <div className='flex justify-end space-x-4'>
          <button
            onClick={handleSelect}
            disabled={!dataSourceId}
            className={`px-4 py-2 font-bold ${
              dataSourceId
                ? 'bg-gradient-to-r from-purple-500 via-blue-500 to-blue-400 text-white'
                : 'bg-gray-300 cursor-not-allowed'
            } rounded`}
          >
            Create
          </button>
          <button
            onClick={onClose}
            className='px-4 py-2 font-bold text-gray-700 rounded-lg border border-gray-300 hover:bg-gray-200 focus:outline-none'
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddComponent;
