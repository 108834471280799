import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import CustomSignUpForm from '../../blocks/CustomSignUpForm';

const SignUpPage: React.FC = () => {
  const { theme } = useTheme();

  const pageStyle: React.CSSProperties = {
    backgroundColor: theme.primary,
    color: theme.secondary,
    padding: '20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  return (
    <div style={pageStyle}>
      <h1 className="text-2xl font-bold mb-4">Sign Up</h1>
      <CustomSignUpForm />
    </div>
  );
};

export default SignUpPage;
