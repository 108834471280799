export interface MessageType1 {
    role: string;
    content: string;
  }
  
export  interface MessageType2 {
    chart_type: string;
    data: {
      data: any[],
      layout: any,
    }
  }

export const isUserMessage = (message: MessageType1 | MessageType2): message is MessageType1 => (
    'role' in message
)

export  function isAssistantMessage(message: MessageType1 | MessageType2): message is MessageType1 {
    return 'role' in message;
  }
  
export  function isChartMessage(message: MessageType1 | MessageType2): message is MessageType2 {
    return 'chart_type' in message && 'data' in message;
  }