import React, { useState, useEffect } from 'react';
import { DataSource } from '../../api/apiModels';
import { TfiViewGrid, TfiViewListAlt } from 'react-icons/tfi';
import DataSourceCard from '../../components/_data_sources/DataSourceCard';
import { SiMongodb } from 'react-icons/si';
import CustomButton from '../../blocks/CustomButton';
import CustomInput from '../../blocks/CustomInput';
import { Table } from 'flowbite-react';

interface MyDataSourcesProps {
  dataSources: DataSource[]; // Define the prop type
  addNewDataSource: () => void;
}

const MyDataSources: React.FC<MyDataSourcesProps> = ({ dataSources, addNewDataSource }) => {
  const [filteredDataSources, setFilteredDataSources] = useState<DataSource[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [view, setView] = useState<'list' | 'grid'>('list');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false); // Since we're receiving data from props, set loading to false
  }, []);

  useEffect(() => {
    // Filter data sources based on search term
    const filtered = dataSources.filter((dataSource) =>
      dataSource.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDataSources(filtered);
  }, [searchTerm, dataSources]);

  return (
    <div className='flex flex-col px-4 py-2 bg-gray-50 dark:bg-gray-700'>
      <div className='flex flex-col md:flex-row md:justify-between md:items-center mb-4'>
        <CustomInput
          placeholder='Search Data Source'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className='flex flex-row h-12 gap-2 items-center'>
          <div className='flex flex-row items-center justify-center border border-gray-300 rounded-md'>
            <TfiViewListAlt
              onClick={() => setView('list')}
              className={`font-extrabold text-4xl p-3 cursor-pointer ${view === 'list' ? ' bg-gray-200' : ''}`}
            />
            <TfiViewGrid
              onClick={() => setView('grid')}
              className={`font-extrabold text-4xl p-3 cursor-pointer ${view === 'grid' ? ' bg-gray-200' : ''}`}
            />
          </div>
          <CustomButton onClick={addNewDataSource}>Add Data Source</CustomButton>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : filteredDataSources.length === 0 ? (
        <p>No data sources found.</p>
      ) : view === 'list' ? (
        <Table>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>DB Name</Table.HeadCell>
            <Table.HeadCell>DB Type</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {filteredDataSources.map((dataSource) => (
              <Table.Row key={dataSource.id}>
                <Table.Cell>{dataSource.name}</Table.Cell>
                <Table.Cell>{dataSource.database_name}</Table.Cell>
                <Table.Cell><SiMongodb className='text-green-600 text-xl' /></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div className="flex flex-wrap gap-4">
          {filteredDataSources.map((dataSource) => (
            <div key={dataSource.id} className='flex flex-col m-2 justify-between border border-gray-300 rounded-md'>
              <DataSourceCard dataSource={dataSource}/>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyDataSources;
