import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchAllProjects } from '../../api/project/apiProjectServices';
import { MinimalProject } from '../../api/apiModels';

// Define the shape of the project context
interface ProjectContextType {
  projects: MinimalProject[];
  totalProjects: number; // Include totalProjects
  fetchProjects: () => Promise<void>;
  getProjectById: (projectId: string) => Promise<MinimalProject | undefined>; // Correct return type
  isProjectLoading: boolean;
  error: Error | null;
}

// Initialize the context with default values
const ProjectContext = createContext<ProjectContextType>({
  projects: [],
  totalProjects: 0,
  fetchProjects: async () => {},
  getProjectById: async () => undefined, // Correct default return value
  isProjectLoading: false,
  error: null,
});

// Custom hook to access the project context
export const useProjectContext = () => {
  return useContext(ProjectContext);
};

// ProjectProvider component to wrap your application and provide project context
export const ProjectProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [projects, setProjects] = useState<MinimalProject[]>([]);
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  // Function to fetch projects
  const fetchProjects = async () => {
    try {
      setIsProjectLoading(true);
      const fetchedProjects = await fetchAllProjects();
      setProjects(fetchedProjects.projects);
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsProjectLoading(false);
    }
  };

  // Function to get project by ID
  const getProjectById = async (projectId: string) => {
    try {
      const project = projects.find((project) => project.id === projectId);
      return project;
    } catch (error) {
      return undefined; // Return undefined if not found
    }
  };

  // Effect to fetch projects when the component mounts
  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        totalProjects: projects.length, // Calculate totalProjects
        fetchProjects,
        getProjectById,
        isProjectLoading,
        error,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
