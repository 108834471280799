import React, { useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { MessageType1, MessageType2, isAssistantMessage, isChartMessage, } from '../../../../api/_models/message';
import UserMessage from '../../../../components/_chat/UserMessage';
import AssistantMessage from '../../../../components/_chat/AssistantMessage';

interface MessagesProps {
  messages: (MessageType1 | MessageType2)[];
  chartClick: () => void;
  isMessageSent: boolean;
}

const MessageBox: React.FC<MessagesProps> = ({ messages, chartClick, isMessageSent }) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the bottom when new messages arrive
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={messageContainerRef}
      className='flex flex-col w-11/12 md:w-2/3 rounded-md overflow-hidden overflow-y-scroll scroll-smooth dark:bg-gray-600'
      style={{ scrollbarWidth: 'none' }}
    >
      {messages.map((message, index) => (
        <div key={index} className='flex flex-row w-full'>
          {isAssistantMessage(message) ? (
            message.role === 'assistant' ? <AssistantMessage text={message.content} /> : <UserMessage text={message.content} /> 
          ) : isChartMessage(message) ? (
            <div className='flex flex-col items-center justify-center'>
              <Plot
                data={message.data.data}
                layout={message.data.layout}
                onClick={chartClick}
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ) : null}
        </div>
      ))}
      { isMessageSent &&
      <div key={messages.length + 1} className='flex flex-row w-full'>
        <AssistantMessage text={
          <div className='flex gap-2 items-center h-full'>
            <div className="w-4 h-4 rounded-full bg-purple-400 dark:bg-purple-400 animate-bounce"></div>
            <div className="w-4 h-4 rounded-full bg-purple-400 dark:bg-purple-400 animate-bounce animation-delay-[250ms]"></div>
            <div className="w-4 h-4 rounded-full bg-purple-400 dark:bg-purple-400 animate-bounce animation-delay-[500ms]"></div>
          </div>
          }
        />
    </div>
}
    </div>
  );
};

export default MessageBox;
