// LoginPage.tsx
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import GoogleLogin from '../../blocks/GoogleLogin';
import { useAuthContext } from '../../contexts/AuthContext';

const LoginPage: React.FC = () => {
  const {logout, isAuthenticated} = useAuthContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { login } = useAuthContext();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const res = await login({
        username,
        password,
        grant_type: null,
        scope: '',
        client_id: null,
        client_secret: null
      });
      if(res) {
        navigate('/'); 
      }
    } catch (error) {
      setError('Invalid email or password. Please try again.');
    }
  };

  const validateForm = () => {
    return username.trim() !== '' && password.trim() !== '';
  };

  useEffect(() => {
    if(isAuthenticated)
    return () => {
      logout();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-screen justify-center items-center">
      <form className="flex flex-col gap-4 justify-center items-center py-4 px-8 border-2 rounded-md border-gray-300 text-gray-600 shadow">
        <div className="text-xl font-bold">Login here</div>
        <div className="flex flex-col justify-center items-center gap-4">
          <input
            type="text"
            name="username"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="rounded-md border border-gray-300 shadow focus:ring-gray-400"
          />
          <input
            type="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-md border border-gray-300 shadow focus:ring-gray-400"
          />
        </div>
        <div className="flex gap-10">
          <button
            type="button"
            onClick={handleLogin}
            disabled={!validateForm()}
            className="px-2 py-1 rounded-md border-2 border-gray-300 shadow"
          >
            Log In
          </button>
          <Link to="/signup" className="px-2 py-1 rounded-md border-2 border-gray-300 shadow">
            Sign Up
          </Link>
        </div>
        {error && <div className="text-red-500">{error}</div>}
        <Link to="/forget-password" className="ml-auto italic">
          Forgot Password?
        </Link>
        <GoogleLogin />
        <button onClick={() => navigate('..')}>
          <BiArrowBack className="text-xl font-bold" />
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
