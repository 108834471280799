// AssistantMessage.tsx
import React from 'react';
import { CgBot } from 'react-icons/cg';

interface AssistantMessageProps {
  text: React.ReactNode;
}

const AssistantMessage: React.FC<AssistantMessageProps> = ({ text }) => (
  <div className='flex justify-start align-top mb-2 ml-2 mr-auto'>
    <CgBot className='ml-1 text-4xl'/>
    <div className='rounded-md bg-gray-300 w-2/3 gap-1 py-1 px-2 ml-1 dark:bg-gray-700 dark:text-gray-50'>
      {text}
    </div>
  </div>
);

export default AssistantMessage;
