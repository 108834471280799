import axios from 'axios';
import { DATA_SOURCE_ENDPOINTS } from './apiEndpoints';
import { DataSource } from './apiModels';

// Service to fetch all data sources
export const fetchAllDataSources = async (): Promise<DataSource[]> => {
  try {
    const response = await axios.get(DATA_SOURCE_ENDPOINTS.READ_ALL_DATA_SOURCES, 
      {
        headers: {
          'Content-Type': 'application/json', // Specify content-type
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      );
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch data sources');
  }
};
