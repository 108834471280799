import axios from 'axios';
import { CONVERSATION_ENDPOINTS } from '../apiEndpoints';
import { Message } from '../apiModels';

export const askAnalyst = async (
  conversationId: string,
  projectId: string,
  dataSourceId: string,
  message: Message
) => {
  try {
    const token = localStorage.getItem('accessToken');

    const url = `${CONVERSATION_ENDPOINTS.ASK_ANALYST}?conversation_id=${conversationId}&project_id=${projectId}&data_source_id=${dataSourceId}`;

    const requestData = {
      content_type: 'message',
      content: message.content
    };

    const response = await axios.put(url, requestData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
