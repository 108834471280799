import React from 'react';

interface CustomInputProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: React.FC<CustomInputProps> = ({ placeholder, value, onChange }) => {
  return (
    <input
      className='h-12 border rounded-md bg-gray-50 border-gray-200 p-2 w-64 md:w-96'
      type='text'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomInput;
