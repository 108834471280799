import React from 'react';
import { IconType } from 'react-icons';
import { FaDatabase, FaSearch, FaUsers, FaCogs, FaExpandArrowsAlt, FaLightbulb } from 'react-icons/fa';

interface FeatureProps {
  title: string;
  description: string;
  Icon: IconType;
}

const Feature: React.FC<FeatureProps> = ({ title, description, Icon }) => {
  return (
    <div className="flex flex-col p-4 m-8 border border-blue-800 rounded-lg md:w-1/4">
      <Icon className="w-12 h-12 mb-4 p-2 bg-blue-800 text-gray-200 rounded-full md:w-16 md:h-16 md:mr-4" />
      <div>
        <h3 className="text-lg font-bold">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Features: React.FC = () => {
    const featuresData = [
        {
          title: 'Democratizing Data',
          description: 'We empower analysts and BI developers to guide less technical colleagues in generating valuable insights from data. This frees up their time for more complex tasks.',
          Icon: FaDatabase,
        },
        {
          title: 'Streamlining Data Discovery',
          description: 'Our conversational interface allows users to ask high-level questions about their data, leading to uncovering hidden patterns and opportunities that might otherwise be missed.',
          Icon: FaSearch,
        },
        {
          title: 'Boosting Collaboration',
          description: 'With AR/LLAM, analysts and data scientists can collaborate more effectively with business stakeholders, leading to informed decision-making across departments.',
          Icon: FaUsers,
        },
        {
          title: 'Increased Efficiency',
          description: 'Replace routine tasks with automation, enabling data experts to proactively shape the future with advanced analytics and optimized data flows.',
          Icon: FaCogs,
        },
        {
          title: 'Scalability and Flexibility',
          description: 'Our AI scales to meet any data challenge, handling complex questions on vast datasets, all while integrating seamlessly with your existing systems.',
          Icon: FaExpandArrowsAlt,
        },
        {
          title: 'Data-Driven Culture',
          description: 'Making data analysis accessible and intuitive, AR/LLAM can cultivate a data-driven culture within organizations. Empowering employees to leverage data for better decision-making.',
          Icon: FaLightbulb,
        },
      ];

  return (
    <div className='flex flex-wrap justify-center min-h-screen w-screen bg-gradient-to-b from-blue-50 to-purple-50 p-4'>
        <h1 className='mt-4 text-xl md:text-3xl font-bold text-blue-900'>Revolutionizing you relations with data</h1>
        <div className="flex flex-wrap justify-center bg-gradient-to-b from-blue-50 to-purple-50 p-4">
          {featuresData.map((feature) => (
            <Feature key={feature.title} title={feature.title} description={feature.description} Icon={feature.Icon} />
          ))}
        </div>
    </div>
  );
};

export default Features;
