/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';

interface ConnectionParameters {
  mongodb_connection_string: string;
  redirect_uri: string;
  json_object: {};
}

interface DataSourceInput {
  name?: string;
  connector_type?: string;
  connection_type?: string;
  connection_parameters: ConnectionParameters;
  database_name?: string;
}

interface AddDataSourceFormProps {
  onClose: () => void;
}

const ConnectionUrlInput: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">Connection URL</label>
    <input
      type="text"
      placeholder="Enter URL..."
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="mt-1 p-2 border rounded w-full"
    />
  </div>
);

const TestConnectionButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button onClick={onClick} className="bg-blue-500 text-white px-4 py-2 rounded">
    Test Connection
  </button>
);

const DatabaseSelect: React.FC<{ value: string; options: string[]; onChange: (value: string) => void }> = ({
  value,
  options,
  onChange,
}) => (
  <div className="mt-4">
    <label className="block text-sm font-medium text-gray-700">Select Database</label>
    <select
      value={value}
      onChange={(e) => {
        console.log('Selected value:', e.target.value);
        onChange(e.target.value);
      }}
      className="w-full p-2 border rounded"
    >
      <option value="" disabled>
        Select Database
      </option>
      {options.map((db) => (
        <option key={db} value={db}>
          {db}
        </option>
      ))}
    </select>
  </div>
);

const NameInput: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => (
  <div className="mt-4">
    <label className="block text-sm font-medium text-gray-700">Name</label>
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="mt-1 p-2 border rounded w-full"
    />
  </div>
);

const SaveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button onClick={onClick} className="bg-green-500 text-white px-4 py-2 rounded mt-4">
    Save
  </button>
);

const AddDataSourceForm: React.FC<AddDataSourceFormProps> = ({ onClose }) => {
  const formRef = useRef<HTMLDivElement>(null);
  const [connectionUrl, setConnectionUrl] = useState('');
  const [databasesNames, setDatabasesNames] = useState<string[]>([]);
  const [selectedDatabase, setSelectedDatabase] = useState<string>('');
  const [name, setName] = useState('');
  const connectorType = 'mongodb';

  const handleTestConnection = async () => {
    try {
      // const response = await testDataSource(connectionUrl);

      // setDatabasesNames(response.database_names);
    } catch (error) {
      console.error('Connection test failed:', error);
    }
  };

  const handleSave = async () => {
    try {
      const connectionParams: ConnectionParameters = {
        mongodb_connection_string: connectionUrl,
        redirect_uri: '',
        json_object: {},
      };

      const dataSourceInput: DataSourceInput = {
        name,
        connector_type: connectorType,
        connection_type: 'uri',
        connection_parameters: connectionParams,
        database_name: selectedDatabase,
      };

      // const response = await saveDataSource(dataSourceInput);
      // if (response) {
      //   console.info('Data source saved! response ::::::::::: ', response);
      //   onClose();
      // }
    } catch (error) {
      console.error('Error saving data source:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, formRef]);  

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center min-w-[400px] max-w-[600px] min-h-[200px] max-h-[100vh] bg-white p-8 rounded shadow-md z-50">
      <h2 className="text-xl font-bold mb-4">Add Data Source</h2>
      <ConnectionUrlInput value={connectionUrl} onChange={setConnectionUrl} />
      <TestConnectionButton onClick={handleTestConnection} />

      {databasesNames.length > 0 && <DatabaseSelect value={selectedDatabase} options={databasesNames} onChange={setSelectedDatabase} />}

      {selectedDatabase.length > 0 && (
        <div>
          <NameInput value={name} onChange={setName} />
          <SaveButton onClick={handleSave} />
        </div>
      )}
      <button onClick={onClose}>Cancle</button>
    </div>
  );
};

export default AddDataSourceForm;
