import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddComponent from './AddComponent';
import AddDashboard from './AddDashboard';
import MyProject from './MyProject';
import { MdOutlineDelete, MdOutlineModeEdit } from "react-icons/md";
import { deleteProject } from '../../../api/project/apiProjectServices';
import { MinimalProject } from '../../../api/apiModels';
import { useProjectContext } from '../../../contexts/project/ProjectContext';
import CustomButton from '../../../blocks/CustomButton';

const ProjectPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { getProjectById, fetchProjects } = useProjectContext();
  const [project, setProject] = useState<MinimalProject | null>(null);

  const [open, setOpen] = useState(false);
  const [opn, setOpn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const fetchedProject = await getProjectById(projectId);
          if(fetchedProject)
          setProject(fetchedProject);
        } catch (error) {
          console.error('Error fetching project:', error);
        }
      }
    };

    fetchProject();
  }, [projectId, getProjectById]);

  const handleDeleteProject = async (id: string) => {
    try {
      const res = await deleteProject(id);
      if(res) {
        fetchProjects()
        navigate('/');
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  return (
    <div className='flex flex-col dark:bg-gray-700 p-8'>
      <div className='flex items-center justify-between'>
        <h1 className="flex items-center text-2xl font-semibold bg-gradient-to-r from-purple-500 via-blue-600 to-blue-500 bg-clip-text text-transparent">
          {project?.name}
          <MdOutlineModeEdit className="ml-2 text-xl text-blue-500 cursor-pointer hover:text-blue-700" />
        </h1>
        <div className='flex items-center gap-4'>
          <CustomButton onClick={() => setOpen(true)} children={'New Chart'}/>
          <CustomButton onClick={() => setOpn(true)} children={'New Dashboard'}/>
          <div className="flex flex-row items-center justify-start">
            {/* <p>Last Activity: {project?.created_at && formatDate(project?.created_at)}</p> */}
            {projectId && <MdOutlineDelete onClick={() => handleDeleteProject(projectId)} className='text-4xl m-1' title='Delete Project'/> }
          </div>
        </div>
      </div>
      { projectId && <MyProject projectId={projectId} /> }
      {open && <AddComponent onClose={() => setOpen(false)} projectId={projectId}/>}
      {opn && <AddDashboard onClose={() => setOpn(false)} projectId={projectId}/>}
    </div>
  );
}

export default ProjectPage;
