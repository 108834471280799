import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/custom.css';
import { ThemeProvider } from './contexts/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import dayjs from 'dayjs';
const advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const root = document.getElementById('root') as HTMLElement;

createRoot(root).render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider>
    <AuthProvider>
        <App />
    </AuthProvider>
    </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
