import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchAllDataSources } from '../api/apiDatasourceServices';
import { DataSource } from '../api/apiModels';

interface DataSourceProviderProps {
  children: ReactNode;
}

interface DataSourceContextType {
  dataSources: DataSource[];
  totalDataSources: number;
  isDataSourcesLoading: boolean; // Include isDataSourcesLoading
  error: Error | null; // Include error
  fetchDataSources: () => Promise<void>;
}

const DataSourceContext = createContext<DataSourceContextType>({
  dataSources: [],
  totalDataSources: 0,
  isDataSourcesLoading: false, // Initialize isDataSourcesLoading to false
  error: null, // Initialize error to null
  fetchDataSources: async () => {},
});

export const useDataSourceContext = () => {
  return useContext(DataSourceContext);
};

export const DataSourceProvider: React.FC<DataSourceProviderProps> = ({ children }) => {
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [totalDataSources, setTotalDataSources] = useState<number>(0);
  const [isDataSourcesLoading, setIsDataSourcesLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchDataSources = async () => {
    try {
      setIsDataSourcesLoading(true);
      const fetchedDataSources = await fetchAllDataSources();
      setDataSources(fetchedDataSources);
      setTotalDataSources(fetchedDataSources.length);
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsDataSourcesLoading(false);
    }
  };

  useEffect(() => {
    fetchDataSources();
  }, []);

  const value: DataSourceContextType = {
    dataSources,
    totalDataSources,
    isDataSourcesLoading,
    error,
    fetchDataSources,
  };

  return (
    <DataSourceContext.Provider value={value}>
      {children}
    </DataSourceContext.Provider>
  );
};
