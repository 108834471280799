import React, { createContext, useState, useContext, ReactNode } from 'react';

type Theme = {
  primary: string;
  secondary: string;
  accent: string;
};

type Themes = {
  [key: string]: Theme;
};

interface ThemeContextProps {
  theme: Theme;
  toggleTheme: () => void;
}

// custom themes
const themes: Themes = {
  'blue': {
    'primary': '#0000ff',
    'secondary': '#0000cc',
    'accent': '#000000'
  },
  'custom': {
    'primary': '#f4f2f0',
    'secondary': '#313035',
    'accent': '#c8d7d2'
  },
  'dark': {
    'primary': '#000000',
    'secondary': '#333333',
    'accent': '#000000'
  },
  'light': {
    'primary': '#ffffff',
    'secondary': '#cccccc',
    'accent': '#000000'
  },
};

export const ThemeContext = createContext<ThemeContextProps>({
  theme: themes.custom, // default theme
  toggleTheme: () => {}, // default function
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<string>('custom');
  const theme = themes[themeName];

  const toggleTheme = () => {
    const themeNames = Object.keys(themes);
    const i = themeNames.indexOf(themeName);
    const nextThemeName = themeNames[(i + 1) % themeNames.length];
    setThemeName(nextThemeName);
    const nextTheme = themes[nextThemeName];
    document.documentElement.style.setProperty('--theme-primary', nextTheme.primary);
    document.documentElement.style.setProperty('--theme-secondary', nextTheme.secondary);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
