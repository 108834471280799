import { AxiosRequestConfig } from "axios";

// Base URL for API
const BASE_URL = 'https://api.arllam.com/';

export const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
};

// Authentication endpoints
export const AUTH_ENDPOINTS = {
  LOGIN: `${BASE_URL}login`,
  SIGNUP: `${BASE_URL}signup`,
  GET_OTP: `${BASE_URL}get-otp`,
  VERIFY_OTP: `${BASE_URL}verify-otp`,
  CREATE_PASSWORD: `${BASE_URL}create-password`,
};

// Project endpoints
export const PROJECT_ENDPOINTS = {
  FETCH_ALL_PROJECTS: `${BASE_URL}analytics/project/all`,
  CREATE_PROJECT: (name: string) => `${BASE_URL}analytics/project?name=${name}`,
  DELETE_PROJECT: (projectId: string) => `${BASE_URL}analytics/project/${projectId}/`,
  // Add other project endpoints
};

// Dashboard endpoints
export const DASHBOARD_ENDPOINTS = {
  CREATE_DASHBOARD: (projectId: string, dashboardName: string) => `${BASE_URL}analytics/dashboard/?project_id=${projectId}&dashboard_name=${dashboardName}`,
  UPDATE_DASHBOARD: `${BASE_URL}analytics/dashboard/`,
  FETCH_ALL_DASHBOARDS_IN_PROJECT: (projectId: string) => `${BASE_URL}analytics/dashboard/all/${projectId}`,
  FETCH_DASHBOARD_BY_ID: (dashboardId: string) => `${BASE_URL}analytics/dashboard/${dashboardId}`,
  DELETE_DASHBOARD: (dashboardId: string) => `${BASE_URL}analytics/dashboard/${dashboardId}`,
  // Define other dashboard endpoints
};

// Component endpoints
export const COMPONENT_ENDPOINTS = {
  FETCH_ALL_COMPONENTS_IN_PROJECT: (projectId: string) => `${BASE_URL}analytics/component/all/${projectId}`,
  READ_ITEM: (componentId: string) => `${BASE_URL}analytics/component/${componentId}`,
  DELETE_ITEM: (componentId: string) => `${BASE_URL}analytics/component/${componentId}`,
  // Define other component endpoints
};

// Conversation endpoints
export const CONVERSATION_ENDPOINTS = {
  ASK_ANALYST: `${BASE_URL}conversation/conversations/ask/`,
  // Define other conversation endpoints
};

// New Endpoints
export const DATA_SOURCE_ENDPOINTS = {
  READ_ALL_DATA_SOURCES: `${BASE_URL}data_source/all`,
};

export const FETCH_CONVERSATION_BY_ID_ENDPOINT = (conversationId: string) =>
  `${BASE_URL}conversation/conversations/${conversationId}`;

// Add more endpoints as needed
