import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { login } from '../api/apiAuthServices';
import { BodyLoginLoginPost, JWTToken } from '../api/apiModels';
import { useNavigate } from 'react-router';

// Define the shape of the authentication context
interface AuthContextType {
  isAuthenticated: boolean;
  login: (loginData: BodyLoginLoginPost) => Promise<boolean>;
  logout: () => void;
}

// Initialize the context with default values
const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  login: async () => {
    throw new Error('Login function is not implemented.');
  },
  logout: () => {
    throw new Error('Logout function is not implemented.');
  },
});

// Custom hook to access the authentication context
export const useAuthContext = () => {
  return useContext(AuthContext);
};

// AuthProvider component to wrap your application and provide authentication context
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  // Effect to check if user is already authenticated
  useEffect(() => {
    // Check if user is already authenticated by any means (e.g., stored token in local storage)
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setIsAuthenticated(true);
    }
  }, []);

  // Function to handle user login
  const handleLogin = async (loginData: BodyLoginLoginPost): Promise<boolean> => {
    if (!loginData) {
      throw new Error('Invalid login data.');
    }
    try {
      const { username, password } = loginData; // Destructure username and password from loginData
      // Call the login service with username and password
      const token: JWTToken = await login(username, password);
      // Set the authentication status and store the access token
      if (token.access_token) {
        setIsAuthenticated(true);
        localStorage.setItem('accessToken', token.access_token);
        return true; // Return true on successful login
      } else {
        return false; // Return false if access token is not present
      }
    } catch (error) {
      // Handle login errors
      console.error('Login failed:', error);
      return false; // Return false on login failure
    }
  };

  // Function to handle user logout
  const handleLogout = () => {
    // Clear the stored token and set authentication status to false
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
