import React, { useState } from 'react';
import { FaRegFolder } from "react-icons/fa6";
import { FiDatabase } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { MdOutlineLogout } from "react-icons/md";
import { HiMenuAlt4 } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import { useDataSourceContext } from '../../contexts/DataSourceContext';
import { useProjectContext } from '../../contexts/project/ProjectContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { DarkThemeToggle } from 'flowbite-react';

const MySideBar: React.FC = () => {
  const { logout } = useAuthContext();
  const { totalDataSources } = useDataSourceContext();
  const { totalProjects } = useProjectContext();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  return (
    <div className='flex flex-col dark:bg-gray-700 h-full border-r-2 border-gray-300'>
      <div className='flex p-2 ml-2'>
        <HiMenuAlt4 onClick={() => setToggle(!toggle)} className='cursor-pointer' />
      </div>
      <div className='flex-1 flex-col p-2'>
        <div className="flex flex-row h-10 items-center p-2 hover:bg-gray-100 dark:hover:bg-gray-500 cursor-pointer" onClick={() => navigate('/')}>
          <FaRegFolder />
          <div className={`flex flex-row items-center justify-between ml-2 ${!toggle && 'hidden'}`}>
            <span className='w-28'>Projects</span>
            <p className='text-sm rounded-lg bg-gray-100 dark:text-gray-600 px-2 ml-auto'>{totalProjects}</p>
          </div>
        </div>
        <div className="flex flex-row h-10 items-center p-2 hover:bg-gray-100 dark:hover:bg-gray-500 cursor-pointer" onClick={() => navigate('/datasources')}>
          <FiDatabase />
          <div className={`flex flex-row items-center justify-between ml-2 ${!toggle && 'hidden'}`}>
            <span className='w-28'>Data Sources</span>
            <p className='text-sm rounded-lg bg-gray-100 dark:text-gray-600 px-2 ml-auto'>{totalDataSources}</p>
          </div>
        </div>
      </div>
      <div className={`flex items-center justify-between h-14 px-2 border-y-2 border-gray-300 ${!toggle && 'hidden'}`}>
        <div className='flex flex-row items-center p-2'>
          <CgProfile className='text-4xl' />
          <div className='flex-col mx-2'>
            {(
              <>
                <span className='text-lg font-semibold'>{'guest'}</span>
                <p className='text-md font-medium'>view profile</p>
              </>
            )}
          </div>
        </div>
        <MdOutlineLogout onClick={logout} className='text-2xl mr-2 cursor-pointer' />
      </div>
      <div className='flex flex-row items-center justify-center p-2'>
        <DarkThemeToggle className='w-10 m-1'/>
        {toggle && 
         <>
        <p className='text-xs text-gray-600 p-1'>Powered By</p>
        <span className='text-lg font-extrabold p-1 bg-gradient-to-r from-purple-700 via-purple-700 to-blue-700 bg-clip-text text-transparent'>
          ARLLaM
        </span>
        </>
        }
      </div>
    </div>
  );
};

export default MySideBar;
