import React from 'react';
import CustomFooter from './CustomFooter';
import { SiAmazondynamodb, SiAmazonredshift, SiGooglebigquery, SiGooglesheets, SiMongodb, SiPostgresql } from 'react-icons/si';
import { GrMysql } from "react-icons/gr";
import HeroBanner from './HeroBanner';
import HeaderNav from './HeaderNav';
import Features from './Features';

const LandingPage: React.FC = () => {
  return (
    <div className="flex-1">
      <HeaderNav />
      <HeroBanner />
      <Features />
      <DataConnector />
      <AboutUs />
      <CustomFooter/>
    </div>
  );
};

const DataConnector = () => {
  const connectors = [
    { icon: <SiMongodb color="green" size="3em" />, name: "MongoDB" },
    { icon: <SiGooglesheets color="green" size="3em" />, name: "Google Sheets" },
    { icon: <SiGooglebigquery color="blue" size="3em" />, name: "BigQuery" },
    { icon: <GrMysql color="blue" size="3em" />, name: "MySQL" },
    { icon: <SiPostgresql color="blue" size="3em" />, name: "PostgreSQL" },
    { icon: <SiAmazondynamodb color='blue' size="3em" />, name: "Dynamo DB" },
    { icon: <SiAmazonredshift color='blue' size="3em" />, name: "Redshift" },
    // Add more connectors as needed
  ];

  return (
    <div className="h-full md:h-screen w-screen flex flex-col items-center justify-center p-4 bg-gradient-to-b from-blue-50 to-blue-100">
      <h2 className="text-3xl font-bold mb-4 text-blue-900">Data Connectors</h2>
      <div className="flex flex-wrap items-center justify-center gap-5">
        {connectors.map((connector, index) => (
          <div
            key={index}
            className="flex flex-col h-40 w-44 items-center justify-center p-4 bg-gray-100 rounded-md"
          >
            {connector.icon}
            <p className="text-lg font-semibold mt-2 text-blue-900">{connector.name}</p>
          </div>
        ))}
      </div>
      <p className="text-lg mt-8 p-2 text-blue-900 text-center">
        Our product supports a wide range of data connectors to ensure seamless integration
        and compatibility with your preferred data sources.
      </p>
    </div>
  );
};

const AboutUs = () => (
  <div className="h-screen w-screen px-5 text-blue-900 bg-gradient-to-b from-purple-100 via-blue-50 to-blue-100 bg-opacity-70 backdrop-filter backdrop-blur backdrop-brightness-150 flex  flex-col md:flex-row items-center justify-center">
    {/* left side content */}
    <div className="w-full md:w-1/2 flex flex-col md:ml-10 p-4">
     <h2 className="flex justify-center text-3xl font-bold mb-2">About Us</h2>
     <div className='flex flex-col p-4 font-semibold text-lg'>
      <h1 className="font-bold text-xl mb-2">
       At ARLLaM,
      </h1>
      <p>
       Our vision is to make data analytics simple and enjoyable for everyone,
      </p>
      <p>
       by providing a user-friendly and AI-powered platform.
      </p>
    </div>
    <p className="text-lg italic text-gray-800 p-5">“We don’t just love data, we live data.”</p>
    <div className="flex flex-col mb-2 p-2 text-lg font-bold">
    <h1 className='' >With our platform, you can:</h1> 
    <div className='ml-4'>
    <p className="flex-1 mt-2">
     Perform analysis from A to Z, whatever your data goals are.
    </p>
    <p className="flex-1">
      Make data-driven decisions with confidence and ease.
    </p>
    <p className="flex-1">
      Uncover data stories that inspire and inform.
    </p>
    </div>
  </div>
 
  </div>
    {/* right side image */}
    <img
    className="md:w-1/2 mb-4 md:mb-0 hidden md:block"
    src="/_landing/dashboards-hero.svg"
    alt="About Us"
  />
  </div>
);

export default LandingPage;
