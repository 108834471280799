import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const isActive = (path: string) => pathname === path;

  return (
    <nav className="fixed top-0 w-full flex items-center justify-between px-2 py-1 bg-gradient-to-r from-purple-200 via-blue-200 to-blue-100 z-10">
      <div className="h-12 flex flex-row items-center space-x-4 md:ml-12">
      <img src="/_landing/logo.svg" alt="logo" className="h-10 w-10 rounded-full bg-gradient-to-tr from-purple-400 via-purple-200 to-white"/>
      <div className="text-3xl font-extrabold bg-gradient-to-r from-purple-900 via-blue-800 to-blue-600 bg-clip-text text-transparent">
        ARLLaM
      </div>
      </div>
      {/* Navigation links for medium screens and larger */}
      <div className="hidden md:flex space-x-4">
        <button
          className={`${isActive("/") && 'border-b-2 border-blue-600 font-bold bg-gradient-to-r from-purple-900 via-blue-800 to-blue-600 bg-clip-text text-transparent'}`}
          onClick={() => navigate("/")}
        >
          Home
        </button>
        <button
          className={` ${isActive("/product") && 'border-b-2 border-blue-600 font-bold bg-gradient-to-r from-purple-900 via-blue-800 to-blue-600 bg-clip-text text-transparent'}`}
          onClick={() => navigate("/product")}
        >
          Our Product
        </button>
        <button
          className={`${isActive("/pricing") && 'border-b-2 border-blue-600 font-bold bg-gradient-to-r from-purple-900 via-blue-800 to-blue-600 bg-clip-text text-transparent'}`}
          onClick={() => navigate("/pricing")}
        >
          Pricing
        </button>
        </div>
        <div className="hidden md:flex md:mr-12 space-x-4 text-lg font-medium text-white">
          <button
            className="rounded-xl px-3 py-1 bg-gray-50 text-black border-2 border-blue-800 transition duration-300 transform hover:scale-105"
            onClick={() => navigate("/login")}
          >
            Log in
          </button>

          <button
    className="rounded-lg px-3 py-1 bg-blue-600 text-white transition duration-300 transform hover:scale-105 shadow-md"
    onClick={() => navigate("/signup")}
  >
    Sign Up
  </button>
</div>

      {/* Responsive navigation menu for small screens */}
       {/* Responsive menu icon for small screens */}
       <div className="md:hidden cursor-pointer" onClick={() => setMenuOpen(!isMenuOpen)}>
        <svg
          className="w-6 h-6 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </div>
      {isMenuOpen && (
        <div className="md:hidden absolute h-screen w-screen flex flex-col items-center top-full right-0 text-white py-2 px-4 space-y-2">
          {/*<button onClick={() => navigate("/")}>Home</button>
          <button onClick={() => navigate("/about")}>About</button>
          <button onClick={() => navigate("/features")}>Features</button>
          <button onClick={() => navigate("/pricing")}>Pricing</button>
      <button onClick={() => navigate("/contact")}>Contact</button>*/}
        <button className="rounded-md text-xl border-2 w-28 mt-4 p-2"
        onClick={()=>navigate('/login')}>
          Log in
        </button>
        </div>
      )}
    </nav>
  );
};

export default HeaderNav;
