import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeroBanner: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-between h-screen w-full">
      <div className="flex flex-col h-1/2 mt-auto items-center justify-end md:flex-row md:space-x-16 bg-gradient-to-b from-purple-100 to-blue-50">
        <div className='flex flex-row justify-evenly w-full md:w-1/2'>
          <div className="flex flex-col justify-end m-1">
            <img src='/_landing/crunch.svg' alt='crunch' className='w-full h-full mt-auto'/>
            <p className="font-medium p-2">Talk with your data</p>
          </div>
          <div className="flex flex-col justify-end m-1">
            <img src='/_landing/component.svg' alt='chart' className='w-full h-full mt-auto'/>
            <p className="font-medium p-2">Manipulate Dashboards</p>
          </div>
        </div>
        <div className="flex flex-col space-y-4 px-4 mt-2 w-full md:w-1/2">
          <h1 className="text-3xl font-bold">Get data insights in an instant,</h1>
          <div className='text-xl px-2 font-medium'>with <span className='text-2xl font-bold bg-gradient-to-r from-purple-700 via-blue-700 to-blue-500 bg-clip-text text-transparent'>ARLLaM,</span> just <span className='text-2xl font-bold bg-gradient-to-r from-purple-700 via-blue-700 to-blue-500 bg-clip-text text-transparent'>Chat and Tap.</span></div>
          <button onClick={() => navigate('/login')} className="w-60 rounded-md px-4 py-2 font-bold text-xl text-white bg-gradient-to-r from-purple-600 via-blue-600 to-blue-500">Get Started for Free</button>
          <button className='w-24 border-b border-b-gray-700 font-medium'>Know More {'>'}</button>
        </div>
      </div>
      <div className="flex flex-col h-1/2 items-center bg-gradient-to-t from-blue-100 to-blue-50">
        <div className='flex flex-col items-center justify-between w-full md:w-2/3 mt-auto bg-gradient-to-t from-purple-200 to-blue-50 rounded-b mb-4'>
          <div className="flex flex-row ml-auto px-4">
            <p className='p-2 border-2 border-blue-200 rounded-l-xl rounded-tr-xl mr-2 ml-auto w-2/3'>How do my sales look like for the last 3 months?</p>
            <p className="flex items-center justify-center h-12 w-12 mt-auto text-lg font-bold rounded-full p-2 bg-gradient-to-t from-purple-300 to-purple-100">U</p>
          </div>
          <div className="flex flex-row p-2 mr-auto">
            <p className="mt-auto flex items-center justify-center h-12 w-12 text-lg font-bold rounded-full p-2 bg-gradient-to-t from-purple-300 to-purple-100">A</p>
            <img src='/_landing/chart.svg' alt='chart' className='ml-2 w-2/3'/>
          </div>
          <input className="m-4 p-2 w-11/12 md:w-2/3 md:mx-auto rounded" placeholder="Ask something..." />
        </div>
      </div>
      </div>
  );
};

export default HeroBanner;
